import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
// Components
import PartnerBox from "./partnerBox";
// import Carousel from "react-gallery-carousel";
// import "react-gallery-carousel/dist/index.css";
// Assets
import Partner01 from "../../assets/partners/partner01.jpg";
import Partner02 from "../../assets/partners/partner02.jpg";
import Partner03 from "../../assets/partners/partner03.jpg";
import Partner04 from "../../assets/partners/partner04.jpg";
import Partner05 from "../../assets/partners/partner05.jpg";
import Partner06 from "../../assets/partners/partner06.jpg";
import Partner07 from "../../assets/partners/partner07.jpg";
import Partner08 from "../../assets/partners/partner08.jpg";
import Partner09 from "../../assets/partners/partner09.jpg";

class MutipleSlidesPerView extends React.Component {
  state = {
    partners: [
      {
        preview: Partner01,
        id: "1",
      },
      {
        preview: Partner02,
        id: "2",
      },
      {
        preview: Partner03,
        id: "3",
      },
      {
        preview: Partner04,
        id: "4",
      },
      {
        preview: Partner05,
        id: "5",
      },
      {
        preview: Partner06,
        id: "6",
      },
      {
        preview: Partner07,
        id: "7",
      },
      {
        preview: Partner08,
        id: "8",
      },
      {
        preview: Partner09,
        id: "9",
      },
    ],
  };

  render() {
    let partnersRender = null;

    if (this.state.partners) {
      partnersRender = this.state.partners.map((partner) => (
        <div key={partner.id}>
          <PartnerBox partner={partner.preview} />
        </div>
      ));
    }

    const params = {
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 8,
      loop: true,
      breakpoints: {
        1200: {
          slidesPerView: 6,
          spaceBetween: 25,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 28,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 13,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 8,
        },
      },
    };
    return <Swiper {...params}>{partnersRender}</Swiper>;
  }
}
export default MutipleSlidesPerView;
