import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contactSocial.scss";

import InstagramIcon from "../../../assets/contact/instagram.svg";
import TwitterIcons from "../../../assets/contact/twitter.svg";
import LinkedInIcon from "../../../assets/contact/linkedin.svg";

const contactSocial = () => (
  <Row>
    <Col xs={12}>
      <Row center="xs">
        <Col xs={12} lg={1} className="contact__social">
          <img
            src={InstagramIcon}
            alt="Instagram"
            href="https://www.instagram.com/janetxiushi/?hl=en"
            target="_blank"
          />
        </Col>
        <Col xs={12} lg={1} className="contact__social">
          <img
            src={TwitterIcons}
            alt="Twitter"
            href="https://twitter.com/janetxiushi?lang=en"
            target="_blank"
          />
        </Col>
        <Col xs={12} lg={1} className="contact__social">
          <img
            src={LinkedInIcon}
            alt="LinkedIn"
            href="https://ie.linkedin.com/in/janetshi"
            target="_blank"
          />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default contactSocial;
