import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
// import HeroImage from "../../assets/hero/hero-image.png";
//Components
import Button from "../ui-components/button/button";
//React Video Player
import ReactPlayer from "react-player";

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col sm={20} md={12} lg={6}>
          <div className="hero-info">
            <h1 className="weight750 font60">
              Welcome agents! 欢迎！어서 오십시오!{" "}
            </h1>
            <h1 className="weight750 font65">
              Conas ata tu! <br /> ようこそ!
            </h1>
            <p className="font13">
              Ingress Prime, a mobile devices location-based, fantasy, mass
              multiplayer, real-time, strategy augmented reality game!
            </p>
            <Button label="SEND MESSAGE" target={"contact"} />
          </div>
        </Col>
        <Col sm={26} md={10} lg={6}>
          <div className="hero-video">
            <ReactPlayer
              controls
              width="580px"
              height="350px"
              url="https://youtu.be/gvkgbMCSc8A"
            />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
